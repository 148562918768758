require('core-js/features/url-search-params');
require('./commons/polyfills/CustomEvent');
require('./commons/polyfills/NodeList.forEach');

window.YurPlanWidgets = {
    EVENT_TYPES: {
        AUTO_HEIGHT: 'autoHeight',
        CHANGE_STEP: 'changeStep',
        SCROLL_IFRAME: 'scrollIframe',
    },
    STEPS: {
        TICKETS: 'tickets',
        FORM: 'form',
        PRODUCTS: 'products',
        CONFIRMATION: 'confirmation',
        SUMMARY: 'summary',
        LOGIN: 'login',
        PASSWORD: 'password',
    },
    ypFrames: [],
    getParams(ypFrame) {
        return {
            class: 'yurplan-iframe-widget',
            yid: ypFrame.attributes['data-id'].value,
            id: `yurplanwidget${ypFrame.attributes['data-id'].value}`,
            scrolling: 'auto',
        };
    },

    getStyleLink() {
        return `
            margin: 10px 0 15px 0 !important;
            font-size: 13px !important;
            font-family: Helvetica, Arial, sans-serif !important;
            text-decoration: none !important;
            color: #ddd !important;
            display: block !important;`;
    },

    iframeMessageHandler(e) {
        const event = new CustomEvent(e.data.type);
        event.data = {
            id: e.data.id,
            orgaId: e.data.orgaId,
        };
        switch (e.data.type) {
            case this.EVENT_TYPES.AUTO_HEIGHT:
                event.data = {
                    ...event.data,
                    ...e.data.data,
                };
                break;
            case this.EVENT_TYPES.CHANGE_STEP:
                event.data = {
                    ...event.data,
                    ...e.data.data,
                };
                break;
            default:
                break;
        }
        window.dispatchEvent(event);
    },

    autoHeightHandler(e) {
        if (!e.data.height_auto) return;

        let { id } = e.data;
        id = id || e.data.orgaId;

        if (!document.getElementById(`yurplanwidget${id}`)) return;
        document.getElementById(`yurplanwidget${id}`).style.height = `${Number.parseInt(e.data.height, 10)}px`;
    },

    scrollIframeHandler(e) {
        let { orgaId: id } = e.data;
        id = id || e.data.id;

        if (!document.getElementById(`yurplanwidget${id}`)) return;

        document.getElementById(`yurplanwidget${id}`).scrollIntoView();
    },

    changeStepHandler(e) {
        let { id } = e.data;
        id = id || e.data.orgaId;
        this.changeStepCustomHandler(e);
        if (!document.getElementById(`yurplanwidget${id}`)) return;
        let width = '100%';
        if (!e.data.width_auto && e.data.width) {
            ({ width } = e.data);
        }
        document.getElementById(`yurplanwidget${id}`).width = width;
    },

    changeStepCustomHandler(/* e */) {
        /* custom behavior */
    },

    initFrame(ypFrame) {
        const params = this.getParams(ypFrame);
        Object.entries(params).forEach(([key, value]) => {
            ypFrame.setAttribute(key, value);
        });

        const eventListeners = {
            message: this.iframeMessageHandler,
            scrollIframe: this.scrollIframeHandler,
            changeStep: this.changeStepHandler,
            autoHeight: this.autoHeightHandler,
        };

        Object.entries(eventListeners).forEach(([eventType, listener]) => {
            window.addEventListener(eventType, listener.bind(this), false);
        });

        const { parentNode } = ypFrame;
        parentNode.style.cssText = 'clear: both;';

        // Style footer link
        const link = document.querySelector(`.yurplan-widget-link-${params.yid}`);
        if (link) {
            link.style.cssText = this.getStyleLink();
        }
    },

    init() {
        this.ypFrames = document.querySelectorAll('.yurplan-widget-container iframe');
        this.ypFrames.forEach(this.initFrame, this);
    },
};

window.YurPlanWidgets.init();
